import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type imgPosModel = {
  imgposition?: 'left' | 'right';
};

export const MainHeroBg = styled(Grid)<imgPosModel>(({ theme, imgposition }) => ({
  display: 'flex',
  flexDirection: imgposition === 'left' ? 'row-reverse' : 'row',
  justifyContent: 'center',
  alignItems: 'center',
  background: imgposition === 'left' ? 'var(--blancoVerde)' : 'var(--verdeBlanco)',
  borderRadius: 'var(--borderHero)',
  width: '95%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  }
}));

export const HeroTextContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  textAlign: 'right',
  width: '45%',
  padding: '2em 4em 2em 2em',
  gap: '4vh',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: '0em 2em 2em 2em'
  }
}));

export const HeroImgContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'stretch',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center'
  }
}));

export const HeroTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontSize: '3.5vw',
  color: '#fff',
  [theme.breakpoints.down('md')]: {
    fontSize: '8vw',
    width: '7em'
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: '3vw',
    width: '7em'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '56px'
  }
}));

export const MainHeroImg = styled('img')<imgPosModel>(({ theme, imgposition }) => ({
  position: 'relative',
  top: '4vh',
  left: imgposition === 'left' ? '-1vw' : '1vw',
  borderRadius: '0 60px',
  width: '108%',
  [theme.breakpoints.down('md')]: {
    width: '90%',
    fontSize: '8vw',
    top: '-3vh',
    left: '0'
  }
}));

export const FormIframe = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: '500px',
  [theme.breakpoints.down(1025)]: {
    height: '800px'
  }
}));
