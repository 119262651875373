import { Grid } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import mainHero1 from '../../assets/mainhero1.png';
import mainHero2 from '../../assets/mainhero2.png';
import wppGreen from '../../assets/wpp_green.svg';
import { BtnLink } from '../styles/App.styles';
import { HeroImgContainer, HeroTextContainer, HeroTitle, MainHeroBg, MainHeroImg } from './MainHero.styles';

type MainHeroModel = {
  title: string;
  imgPos?: 'left' | 'right';
  testRes?: boolean;
};

const MainHero: React.FC<MainHeroModel> = ({ title, imgPos, testRes }) => {
  useLocation();
  return (
    <Grid
      container
      sx={{ width: '100%', flexDirection: imgPos === 'left' ? 'row-reverse' : 'row' }}
      className="wow fadeInUpAnimation"
    >
      <MainHeroBg item imgposition={imgPos}>
        <HeroTextContainer>
          <HeroTitle variant="h1" sx={{ fontFamily: 'Morebi Rounded' }} className="wow fadeInAnimation" data-wow-delay="200ms">
            {title}
          </HeroTitle>
          {testRes && (
            <BtnLink
              className="wow fadeInAnimation"
              data-wow-delay="200ms"
              href={'https://public.dev.clave.cloud/u/bBWzLd'}
              secondary="true"
              padding="0.7rem 1.5rem"
              sx={{
                lineHeight: { xs: '1.2rem', sm: '2rem', md: '1rem', lg: '1.5rem' },
                width: '75%',
                fontSize: { xs: '3.5vw', sm: '3.5vw', md: '1vw', lg: '1.2vw', xl: '1.2rem' },
                textAlign: 'start',
                gap: '1rem'
              }}
              style={{
                whiteSpace: 'nowrap'
              }}
            >
              <img src={wppGreen} alt="Whatsapp" />
              Contactanos <br /> por WhatsApp
            </BtnLink>
          )}
        </HeroTextContainer>
        <HeroImgContainer className="wow fadeInAnimation" data-wow-delay="200ms">
          <MainHeroImg src={imgPos === 'left' ? mainHero2 : mainHero1} alt="Hero" imgposition={imgPos} />
        </HeroImgContainer>
      </MainHeroBg>
    </Grid>
  );
};

export default MainHero;
