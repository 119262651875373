import { Grid } from '@mui/material';
import React from 'react';
import Title from '../Title/Title';
import { Description, Service, Text, ServiceContainer } from './Services.styles';

function Services() {
  const services = [
    {
      src: '/assets/img/service_1.svg',
      text: 'Otorgamiento de créditos con scoring propio'
    },
    {
      src: '/assets/img/service_2.svg',
      text: 'Adquisición y administración de carteras originadas por terceros'
    },
    {
      src: '/assets/img/service_3.svg',
      text: 'Comercializacion digital a partir de onboarding multimarca'
    },
    {
      src: '/assets/img/service_4.svg',
      text: 'Disponibilidad de nuestra billetera digital de forma gratuita'
    }
  ];
  return (
    <Grid container flexDirection="column">
      <Title>Nuestros servicios</Title>
      <Text className="wow fadeInAnimation" data-wow-delay="200ms">
        El objetivo de la compañía es facilitar el acceso a servicios financieros para que sus clientes cumplan con sus deseos y
        proyectos. Con el apoyo del equipo profesional y aplicando tecnología de vanguardia, ofrecemos las mejores alternativas
        del mercado en cuanto a seguridad, velocidad y simplicidad.
      </Text>
      <ServiceContainer container flexDirection="row" justifyContent="center">
        {services.map((service, index) => (
          <Service
            xs={6}
            lg={3}
            container
            item
            key={service.text}
            flexDirection="column"
            alignItems="center"
            alignContent="center"
          >
            <Grid item className="wow zoomIn" data-wow-delay={`${index * 2}00ms`}>
              <img src={service.src} />
            </Grid>
            <Grid item className="wow fadeInAnimation" data-wow-delay={`${index * 2}00ms`}>
              <Description>{service.text}</Description>
            </Grid>
          </Service>
        ))}
      </ServiceContainer>
    </Grid>
  );
}

export default Services;
