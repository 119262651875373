import { Grid } from '@mui/material';
import React from 'react';
import MainHero from '../components/MainHero/MainHero';
import Features from '../components/Features/Features';
import { AbTestModel } from '../types';
/**
 * @render react
 * @name Home
 * @description Home page
 * @example
 * <Home/>
 */
const Home: React.FC<AbTestModel> = ({ testRes }) => {
  return (
    <Grid className="safeSpace">
      <MainHero testRes={testRes} title="Tu préstamo por descuento de haberes 100% online." />
      <Features testRes={testRes} />
    </Grid>
  );
};

export default Home;
