import { AccordionSummary, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { Answer, BackgroundTitle, Question, TitleTypo } from './Faqs.styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface FaqModel {
  // anchor: string;
  title: string;
  questions: { q: string; a: string }[];
}
export interface HeaderModel {
  faqs: Array<FaqModel>;
}

const FaqsContainer: React.FunctionComponent<HeaderModel> = ({ faqs }) => {
  return (
    <Grid container rowGap="1.5em">
      <BackgroundTitle className="wow fadeInUpAnimation" data-wow-delay="200ms">
        <div style={{ display: 'flex', width: '60%' }}>
          <TitleTypo variant="h1">¿Tenés dudas acerca de nuestros créditos?</TitleTypo>
        </div>
      </BackgroundTitle>
      {faqs.map((faq) => (
        <Fragment key={faq.title}>
          {faq.questions.map((question: { q: string; a: string }) => (
            <Question key={question.q} className="wow fadeInAnimation" data-wow-delay="200ms">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ width: '100%', padding: '1em' }}
              >
                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                  {question.q}
                </Typography>
              </AccordionSummary>
              <Answer>
                <Typography>{question.a}</Typography>
              </Answer>
            </Question>
          ))}
        </Fragment>
      ))}
    </Grid>
  );
};

export default FaqsContainer;
