import { Grid } from '@mui/material';
import React from 'react';
import Title from '../Title/Title';
import { Text } from './Rates.styles';

function Rates() {
  return (
    <Grid container flexDirection="column">
      <Title>TASAS DE INTERÉS</Title>
      <Text className="wow fadeInAnimation" data-wow-delay="200ms">
        La Tasa Nominal Anual (TNA), la Tasa Efectiva Anual (TEA) y el Costo Financiero Total Nominal Anual (CFTNA) varían según
        el perfil crediticio del solicitante del préstamo y plazo de financiación elegido. En todos los casos, la TNA, TEA y el
        CFT aplicable serán informados al momento de ingresar la solicitud del préstamo y antes de su otorgamiento.
        <br />
        <br />
        Tasa Nominal Anual (TNA) con IVA: Mínima: 118,00% - Máxima 169,05% | Tasa Efectiva Anual (TEA) con IVA: Mínima: 208,18% -
        Máxima: 386,25%
        <br />
        <h3>Costo Financiero Total Nominal Anual(CFTNA) con IVA: Mínimo: 208,18% - Máximo 386,25%</h3>
        <br />
        El otorgamiento del préstamo está sujeto a evaluación crediticia. Para más información, consulte los Términos y
        Condiciones.
      </Text>
    </Grid>
  );
}

export default Rates;
