import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Text = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  lineHeight: '1.9',
  maxWidth: '700px',
  margin: '3rem auto 4rem',
  [theme.breakpoints.down('md')]: {
    margin: '2rem auto 4rem'
  }
}));

export const Description = styled(Typography)(({ theme }) => ({
  color: 'var(--violeta)',
  fontWeight: 500,
  fontSize: 15,
  textAlign: 'center',
  marginTop: '-4rem',
  [theme.breakpoints.down('sm')]: {
    marginTop: '-2rem'
  }
}));

export const ServiceContainer = styled(Grid)(() => ({
  margin: '-4rem auto 4rem',
  maxWidth: 950
}));

export const Service = styled(Grid)(({ theme }) => ({
  padding: '1rem',
  [theme.breakpoints.down('md')]: {
    '& img': {
      width: '300px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& img': {
      width: '200px'
    }
  }
}));
