import { Grid } from '@mui/material';
import React from 'react';
// import { BlueCard } from '../styles/App.styles';
import Title from '../Title/Title';
import { Text } from './Defense.styles';

function Defense() {
  return (
    <Grid container flexDirection="column">
      <Title>Defensa al consumidor</Title>
      <Text className="wow fadeInAnimation" data-wow-delay="200ms">
        Defensa al consumidor NACION click{' '}
        <a href="https://www.argentina.gob.ar/produccion/defensadelconsumidor">
          <b>aquí</b>
        </a>
        <br />
        <br /> Defensa al consumidor CABA click{' '}
        <a href="https://www.buenosaires.gob.ar/defensaconsumidor">
          <b>aquí</b>
        </a>
        <br />
        <br /> Ley N° 24.240 de Defensa del Consumidor. Artículo 10 Ter. Modos de Rescisión. Cuando la contratación de un
        servicio, incluidos los servicios públicos domiciliarios, haya sido realizada en forma telefónica, electrónica o similar,
        podrá ser rescindida a elección del consumidor o usuario mediante el mismo medio utilizado en la contratación.
        <br />
        <br />
        La empresa receptora del pedido de rescisión del servicio deberá enviar sin cargo al domicilio del consumidor o usuario
        una constancia fehaciente dentro de las SETENTA Y DOS (72) horas posteriores a la recepción del pedido de rescisión. Esta
        disposición debe ser publicada en la factura o documento equivalente que la empresa enviare regularmente al domicilio del
        consumidor o
      </Text>
    </Grid>
  );
}

export default Defense;
