import { Grid } from '@mui/material';
import React from 'react';
import FinanceUser from '../components/FinanceUser/FinanceUser';

/**
 * @render react
 * @name FinanceUser
 * @description FinanceUser page
 * @example
 * <FinanceUser/>
 */
const UsuarioFinan = () => {
  return (
    <>
      <Grid className="safeSpace" id="financeUser">
        <FinanceUser />
      </Grid>
    </>
  );
};
export default UsuarioFinan;
