import React from 'react';
import { Grid } from '@mui/material';
import Rates from '../components/Rates/Rates';

/**
 * @render react
 * @name Tasas
 * @description Tasas page
 * @example
 * <Tasas/>
 */
const Tasas = () => {
  return (
    <>
      <Grid className="safeSpace" id="tasas">
        <Rates />
      </Grid>
    </>
  );
};
export default Tasas;
