import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Text = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  lineHeight: '1.9',
  maxWidth: '700px',
  margin: '3rem auto 4rem',
  [theme.breakpoints.down('md')]: {
    margin: '2rem auto 4rem'
  }
}));
