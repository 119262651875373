import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// import { Grid } from '@mui/material';

// type ButtonModel = {
//   primary?: boolean;
//   secondary?: boolean;
//   mobile?: boolean;
// };

export const Background = styled('footer')(({ theme }) => ({
  justifyContent: 'center',
  display: 'flex',
  color: 'white',
  padding: '6rem',
  minHeight: '420px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '100% 100%',
  backgroundColor: 'var(--violeta)',
  backgroundImage: 'url(/assets/img/clave_iso.svg)',
  backgroundSize: '300px 300px',
  [theme.breakpoints.down('md')]: {
    padding: '2rem'
  }
}));

export const Columns = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4rem'
}));

export const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    minHeight: '4rem'
  }
}));

export const FooterLink = styled(Typography)(() => ({
  margin: '20px 0 !important'
}));
