import { AppBar, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface MobileMenuModel {
  showmenu?: boolean;
}

export const StyledAppBar = styled(AppBar)({
  backgroundColor: 'white',
  height: '5rem',
  maxWidth: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row'
});

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '1800px',
  [theme.breakpoints.up('md')]: {
    padding: '0 4em'
  },
  [theme.breakpoints.up('xs')]: {
    padding: '0 2em'
  }
}));

export const StyledItems = styled('li')(({ theme }) => ({
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export const NavbarTypo = styled(Typography)((props: { color: string }) => ({
  fontSize: 14,
  fontWeight: 'bold',
  color: props.color ? props.color : 'var(--gris)'
}));

export const ClaveIcon = styled('img')((props: { path: string; section: string }) => ({
  marginRight: '0.1rem',
  display: props.path === props.section ? 'block' : 'none',
  width: '25%'
}));

export const StyledList = styled('ul')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  textDecoration: 'none',
  listStyleType: 'none',
  fontSize: '19px',
  alignItems: 'center',
  width: '100%'
});

export const StyledMobileList = styled('ul')({
  display: 'flex',
  width: '90%',
  flexDirection: 'column',
  textDecoration: 'none',
  listStyleType: 'none',
  padding: '0',
  margin: 0
});

export const StyledMobileItems = styled('li')({
  padding: '1rem 0',
  borderBottom: '1px solid rgba(92, 63, 225, 0.2)'
});

export const LinkMobile = styled(Typography)(() => ({
  color: 'var(--negro)',
  fontWeight: 600,
  fontSize: '1.8rem !important'
}));

export const MobileMenu = styled('nav')<MobileMenuModel>(({ theme, showmenu }) => ({
  top: `${showmenu ? '0px !important' : '-100vh'}`,
  overflow: 'scroll',
  transition: 'all 350ms cubic-bezier(0.4, 0, 0.2, 1) 200ms',
  visibility: `${showmenu ? 'visible' : 'hidden'}`,
  position: 'fixed',
  opacity: `${showmenu ? '1' : '0'}`,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: 'white linear-gradient(20deg, rgba(205, 208, 241) 24.24%, #ffffff 61.27%)',
  zIndex: 10,
  paddingTop: '5rem',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '100% 90%',
    backgroundImage: 'url(/assets/img/clave_iso_navbar.svg)',
    backgroundSize: '75%'
  }
}));

export const Background = styled('footer')(() => ({
  display: 'flex',
  color: 'white',
  padding: '5%',
  minHeight: '420px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '100% 100%',
  backgroundColor: 'var(--violeta)',
  backgroundImage: 'url(/assets/img/clave_iso.svg)',
  backgroundSize: '300px 300px'
}));

export const NavbarIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap'
});
