import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Text = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  lineHeight: '1.9',
  maxWidth: '750px',
  margin: '3rem auto 4rem',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '75%'
  },
  [theme.breakpoints.down('md')]: {
    margin: '2rem auto 4rem',
    maxWidth: '70%'
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%'
  }
}));

export const TextSmall = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  lineHeight: '1.9',
  margin: '3rem auto 4rem',
  width: '95%',
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.05em'
  },
  [theme.breakpoints.down('xl')]: {
    fontSize: '1em'
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.9em'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.8em'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7em'
  }
}));
