import React from 'react';
import { Text, TitleContainer } from './Title.styles';

export interface GridModel {
  id?: string;
  children?: string | JSX.Element | JSX.Element[];
}

const Title: React.FunctionComponent<GridModel> = (props: GridModel) => (
  <TitleContainer className="wow fadeInAnimation">
    <Text variant="h1" {...props} className="wow fadeInAnimation" data-wow-delay="200ms">
      {props.children}
    </Text>
  </TitleContainer>
);

export default Title;
