import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Grid)``;

export const TitleContainer = styled('div')(() => ({
  width: '100%',
  background: 'var(--titleBg)',
  borderRadius: 'var(--borderHero)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const Text = styled(Typography)(() => ({
  color: 'white',
  padding: '2.5rem',
  textAlign: 'center'
}));
