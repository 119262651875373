import { Grid, Typography, InputBase, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Grid)`
  margin: 5rem auto;
`;

export const Title = styled(Typography)`
  color: var(--verde) !important;
  margin: 1rem 0 2rem !important;
`;

export const SubTitle = styled(Typography)`
  margin: 5rem auto;
`;

type InputModel = {
  height?: string;
};

export const FormInput = styled(InputBase)<InputModel>(({ theme, height }) => ({
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    borderRadius: 11,
    position: 'relative',
    backgroundColor: '#fff',
    color: 'var(--gris)',
    border: '1px solid var(--violeta)',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '41px',
    height: `${height ? height : '54px'}`,
    padding: '0.5rem 0.75rem',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.primary.main
    }
  }
}));

export const LabelForm = styled(InputLabel)`
  padding: 0 0 0.4rem 0.75rem;
`;

export const ErrorForm = styled(Typography)`
  padding: 0 0 0 0.75rem;
  color: var(--rojoError);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 41px;
`;

export const ButtonContainer = styled(Grid)`
  justify-content: center;
  display: flex;
  margin: 2rem 0 0;
`;
