import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Grid, Box } from '@mui/material';
import { BtnLink } from '../styles/App.styles';
import { Container, Title, SubTitle, FormInput, LabelForm, ButtonContainer, ErrorForm } from './Form.styles';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line
const uri = `${process.env.REACT_APP_PUBLIC_URL_API}` + '/mensajeria/rest/email/clave/sendContactInformation/?apikey=' + `${process.env.REACT_APP_PUBLIC_APIKEY}`;

type FormModel = {
  subject: string;
  template: string;
  title?: string;
  subTitle?: string;
  align?: 'left' | 'center' | 'right';
  ctaLabel: string;
  ctaWidth?: string;
  ctaHeight?: string;
  inputs: InputsModel[];
};

export type InputsModel = {
  xs: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  name: string;
  rules?: RulesModel;
  label?: string;
  fullWidth: boolean;
  inputProps?: object;
  mask?: string;
  type?: string;
  rows?: number;
};

type RulesModel = {
  required?: string;
  pattern?: PatternModel;
};

type PatternModel = {
  value: RegExp;
  message: string;
};

const Form: React.FC<FormModel> = ({ subject, template, title, subTitle, align, ctaLabel, ctaWidth, ctaHeight, inputs }) => {
  const { handleSubmit, control, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [labelButton, setLabelButton] = useState(ctaLabel);
  const [error, setError] = useState('');
  // eslint-disable-next-line
  const [formInputs, setFormInputs] = useState({} as any);

  useEffect(() => {
    if (Object.keys(inputs).length > 0) {
      let defaultValues = {};
      // eslint-disable-next-line
      inputs.forEach((input: any) => {
        defaultValues = {
          ...defaultValues,
          [input.name]: ''
        };
      });
      setFormInputs(defaultValues);
    }
  }, [inputs]);

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    // eslint-disable-next-line
    console.log(subject);
    // eslint-disable-next-line
    console.log(uuidv4());
    const formdata = {
      ...data,
      source: 'CLAVECREDITOS',
      reason: `${template.toLowerCase()}`
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formdata)
    };

    await fetch(uri, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        reset(formInputs);
        setLoading(false);
        if (data.error === undefined) {
          setLabelButton('Enviado');
        } else {
          setError('Se produjo un error');
          setLabelButton('Reintentar');
        }
      })
      .catch(() => {
        reset(formInputs);
        setError('Se produjo un error');
        setLabelButton('Enviar');
      });
  };

  return (
    <Container container>
      <Grid container sx={{ flexDirection: 'column' }}>
        <Box className="wow fadeInAnimation">
          {title && (
            <Title variant="h2" align={align}>
              {title}
            </Title>
          )}
          {subTitle && (
            <SubTitle variant="body1" align={align}>
              {subTitle}
            </SubTitle>
          )}
        </Box>
        <Grid container flexDirection={'column'} className="wow fadeInAnimation">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container sx={{ flexWrap: 'wrap' }} spacing={2}>
              {inputs.map((props: InputsModel, index) => (
                <Grid
                  item
                  xs={props.xs}
                  sm={props.sm ? props.sm : props.xs}
                  flexDirection={'column'}
                  key={props.name}
                  className="wow fadeInAnimation"
                  data-wow-delay={`${index * 2}00ms`}
                >
                  <Controller
                    name={props.name}
                    control={control}
                    rules={props.rules}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      if (props.mask) {
                        return (
                          <>
                            {props.label && <LabelForm>{props.label}</LabelForm>}
                            <InputMask mask={props.mask} value={value} onChange={onChange}>
                              <FormInput onChange={onChange} value={value} fullWidth={props.fullWidth} error={!!error} />
                            </InputMask>
                            {error && <ErrorForm>{error.message}</ErrorForm>}
                          </>
                        );
                      } else if (props.type && props.type === 'textarea') {
                        return (
                          <>
                            {props.label && <LabelForm>{props.label}</LabelForm>}
                            <FormInput
                              onChange={onChange}
                              value={value}
                              fullWidth={props.fullWidth}
                              error={!!error}
                              multiline
                              rows={props.rows}
                            />
                            {error && <ErrorForm>{error.message}</ErrorForm>}
                          </>
                        );
                      } else {
                        return (
                          <>
                            {props.label && <LabelForm>{props.label}</LabelForm>}
                            <FormInput onChange={onChange} value={value} fullWidth={props.fullWidth} error={!!error} />
                            {error && <ErrorForm>{error.message}</ErrorForm>}
                          </>
                        );
                      }
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <ButtonContainer className="wow fadeInAnimation">
              <BtnLink
                primary={'true'}
                width={ctaWidth}
                height={ctaHeight}
                fontSize={'1.4rem'}
                padding={'0'}
                type="submit"
                loading={loading}
                color={error ? 'error' : 'primary'}
                className="wow fadeInAnimation"
                data-wow-delay="1000ms"
              >
                {labelButton}
              </BtnLink>
            </ButtonContainer>
            {error && (
              <Box>
                <ErrorForm align="center">{error}</ErrorForm>
              </Box>
            )}
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Form;
