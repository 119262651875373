import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

type BtnModel = {
  primary?: 'true';
  secondary?: 'true';
  width?: string;
  height?: string;
  fontSize?: string;
  padding?: string;
};

type CardModel = {
  width?: string;
  height?: string;
};

export const BtnLink = styled(LoadingButton)<BtnModel>(({ width, height, primary, secondary, fontSize, padding }) => ({
  display: 'flex',
  width: width,
  height: height ? height : '100%',
  padding: padding ? padding : '4%',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  textTransform: 'none',
  border: 'none',
  borderRadius: '40px',
  fontFamily: 'Poppins',
  fontSize: fontSize,
  fontWeight: '500',
  color: secondary === 'true' ? 'var(--violeta)' : '#FFFFFF',
  cursor: 'pointer',
  background: secondary === 'true' ? '#FFFFFF' : 'var(--verde)',
  transition: 'none',
  '&:hover': {
    background: primary === 'true' ? 'var(--violetaVerde)' : '#FFFFFF',
    fontWeight: secondary === 'true' ? '600' : ''
  }
}));

export const Card = styled(Grid)<CardModel>(({ width, height }) => ({
  // background: 'var(--blancoCeleste)',
  minWidth: '276px',
  width: width ? width : 'auto',
  height: height ? height : '100%',
  borderRadius: '20px',
  border: '2px solid var(--violeta)',
  justifyContent: 'space-around',
  alignItems: 'center',
  boxShadow: '-20px 20px 0px var(--violeta)',
  padding: '1rem'
}));

export const BlueCard = styled(Grid)(({ theme }) => ({
  background: 'var(--celesteVioleta)',
  borderRadius: 'var(--borderContainer)',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  padding: '3rem 5rem 5rem',
  [theme.breakpoints.down('sm')]: {
    borderRadius: 'var(--borderCard)',
    padding: '1rem'
  }
}));

export const WhiteCard = styled(Grid)(() => ({
  background: 'white',
  borderRadius: 'var(--borderCardSmall)',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: 'var(--negro)',
  padding: '2rem',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  '& h3': {
    fontSize: '30px',
    color: 'var(--verde)',
    fontWeight: 700,
    margin: '0 2.6rem'
  },
  '& h4': {
    margin: '1rem',
    fontSize: '14px',
    color: 'var(--negro)',
    fontWeight: 500
  },
  '& p': {
    fontSize: '14px',
    color: 'var(--gris)',
    lineHeight: '21px'
  }
}));
