import { Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackgroundTitle = styled(Box)(() => ({
  background: 'var(--titleBg)',
  borderRadius: '0px 60px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '13em',
  width: '100%',
  marginBottom: '3rem'
}));

export const TitleTypo = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: 'white',
  [theme.breakpoints.down('sm')]: {
    padding: '0.5rem'
  }
}));

export const Question = styled(Accordion)(() => ({
  border: '1px solid var(--grisClaro)',
  boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.14)',
  borderRadius: '6px',
  width: '100%'
}));

export const Answer = styled(AccordionSummary)(() => ({
  width: '90%',
  paddingBottom: '1em'
}));
