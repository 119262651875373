import { Grid, Box } from '@mui/material';
import React from 'react';
import { BtnLink, Card } from '../styles/App.styles';
import { Container, Title, CardContainer, CardTitle, Line, ImageContainer, ButtonContainer } from './Features.styles';

// Images
import img001 from '../../assets/features_img_001.png';
import img002 from '../../assets/features_img_002.png';
import img003 from '../../assets/features_img_003.png';
import wpp from '../../assets/wpp.svg';
import { useLocation } from 'react-router-dom';
import { AbTestModel } from '../../types';

const Features: React.FC<AbTestModel> = ({ testRes }) => {
  useLocation();
  return (
    <Container container direction="column">
      <Grid sx={{ flexDirection: { xs: 'column-reverse', sm: 'row' } }}>
        <Box className="wow fadeInAnimation">
          <Title variant="h2">¡Conseguí eso que necesitás de una manera simple!</Title>
        </Box>
        <Grid>
          <CardContainer
            container
            item
            sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'center' } }}
            wrap="wrap"
          >
            <Card item xs={10} sm={4} lg={2} className="wow fadeInAnimation" data-wow-delay="200ms" height={'454px'}>
              <Grid container item direction="column">
                <Line />
                <CardTitle variant="h4">¡Es 100% online! Sacalo 24/7, donde quieras.</CardTitle>
              </Grid>
              <ImageContainer>
                <img src={img001} alt={'¡Es 100% online! Sacalo 24/7, donde quieras.'} />
              </ImageContainer>
            </Card>
            <Card item xs={10} sm={4} lg={2} className="wow fadeInAnimation" data-wow-delay="400ms" height={'454px'}>
              <Grid container item direction="column">
                <Line />
                <CardTitle variant="h4">Con mínimos requisitos.</CardTitle>
              </Grid>
              <ImageContainer>
                <img src={img002} alt={'Con mínimos requisitos.'} />
              </ImageContainer>
            </Card>
            <Card item xs={10} sm={4} lg={2} className="wow fadeInAnimation" data-wow-delay="600ms" height={'454px'}>
              <Grid container item direction="column">
                <Line />
                <CardTitle variant="h4">Acreditación rápida y cuotas a tu medida.</CardTitle>
              </Grid>
              <ImageContainer>
                <img src={img003} alt={'Acreditación rápida y cuotas a tu medida.'} />
              </ImageContainer>
            </Card>
          </CardContainer>
        </Grid>
        <ButtonContainer className="wow fadeInAnimation">
          {testRes && (
            <BtnLink
              className="wow fadeInAnimation"
              data-wow-delay="200ms"
              href={'https://public.dev.clave.cloud/u/bBWzLd'}
              primary="true"
              padding="0rem 1.5rem"
              width="13rem"
              sx={{ gap: '0.5rem', lineHeight: '16px' }}
              height={'46px'}
              style={{
                whiteSpace: 'nowrap'
              }}
            >
              <img src={wpp} alt="Whatsapp" />
              Contactanos <br /> por WhatsApp
            </BtnLink>
          )}
        </ButtonContainer>
      </Grid>
    </Container>
  );
};

export default Features;
