import { Grid } from '@mui/material';
import React from 'react';
// import { BlueCard } from '../styles/App.styles';
import Title from '../Title/Title';
import { Text } from './FinanceUser.styles';

function FinanceUser() {
  return (
    <Grid container flexDirection="column">
      <Grid container flexDirection="column" item flexWrap="nowrap" gap={1} justifyContent="center">
        <Title>
          <>
            ATENCIÓN AL USUARIO
            <br />
            DE SERVICIOS FINACIEROS
          </>
        </Title>
        <Text className="wow fadeInAnimation" data-wow-delay="200ms">
          Si tiene alguna consulta o reclamo con respecto a los servicios o productos ofrecidos por Clave Créditos podrá
          realizarlo a través de:
          <br />
          <br />
          Email:{' '}
          <a href={'mailto:info@clavecreditos.com'} style={{ color: 'var(--azul)' }}>
            info@clavecreditos.com
          </a>
          <br />
          <br />
          <b>RESPONSABLES DE ATENCIÓN AL USUARIO DE SERVICIOS FINANCIEROS:</b>
          <br />
          <br />
          Nombre y Apellido (Titular): DIEGO MARCELO TASSARA
          <br />
          Teléfono: 11 5822 6175
          <br />
          Email:{' '}
          <a href={'mailto:dtassara@clavecreditos.com'} style={{ color: 'var(--azul)' }}>
            dtassara@clavecreditos.com
          </a>
          <br />
          Nombre y Apellido (Suplente): LEONARDO BAZÁN CORBO <br />
          Teléfono: 11 6191 7572
          <br />
          Email:{' '}
          <a href={'mailto:lbazan@clavecreditos.com'} style={{ color: 'var(--azul)' }}>
            lbazan@clavecreditos.com
          </a>
          <br />
          <br />
          <b>COMISIONES, CARGOS Y TASAS</b>
          <br />
          Ante la falta de respuesta o disconformidad con la misma podrán contactar al área de Protección al Usuario de Servicios
          Financieros del Banco Central de la República Argentina ingresando a Usuarios Financieros
        </Text>
      </Grid>
      <Grid container flexDirection="row" item gap={1} justifyContent="center" alignItems="center">
        <Grid container item xs={12} md={5} className="wow fadeInAnimation" data-wow-delay="200ms">
          <img width="100%" src="/assets/img/BCRA_1.jpg" />
        </Grid>
        <Grid container item xs={12} md={5} className="wow fadeInAnimation" data-wow-delay="200ms">
          <img width="100%" src="/assets/img/BCRA_2.jpg" />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FinanceUser;
