import { Grid } from '@mui/material';
import React from 'react';
import Title from '../components/Title/Title';
import Form from '../components/Form/Form';

/**
 * @render react
 * @name Baja
 * @description Baja page
 * @example
 * <Baja/>
 */
const Baja = () => {
  const Inputs = [
    {
      xs: 12,
      sm: 6,
      name: 'nameLastname',
      rules: {
        required: 'Nombre y Apellido son requeridos',
        pattern: {
          value: /^[0-9a-zA-Z;.,$ áéíóúÁÉÍÓÚ]+$/,
          message: 'El Nombre y Apellido no puede contener caracteres especiales'
        }
      },
      label: 'Nombre y apellido',
      fullWidth: true
    },
    {
      xs: 12,
      sm: 6,
      name: 'company',
      rules: {
        required: 'Empresa es requerido',
        pattern: {
          value: /^[0-9a-zA-Z;.,$ áéíóúÁÉÍÓÚ]+$/,
          message: 'La Empresa no puede contener caracteres especiales'
        }
      },
      label: 'Empresa',
      fullWidth: true
    },
    {
      xs: 12,
      sm: 6,
      name: 'celphone',
      rules: {
        required: 'Teléfono es requerido'
      },
      label: 'Teléfono',
      fullWidth: true,
      mask: '+54\\9 99 9999 9999'
    },
    {
      xs: 12,
      sm: 6,
      name: 'email',
      rules: {
        required: 'Email es requerido',
        pattern: {
          value:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Por favor, ingrese un email valido'
        }
      },
      label: 'Email',
      fullWidth: true,
      inputProps: {
        type: 'email'
      }
    },
    {
      xs: 12,
      sm: 12,
      name: 'comment',
      rules: {
        required: 'Comentario es requerido',
        pattern: {
          value: /^[0-9a-zA-Z;.,$ áéíóúÁÉÍÓÚ]+$/,
          message: 'El comentario no puede contener caracteres especiales'
        }
      },
      label: 'Comentarios',
      fullWidth: true,
      type: 'textarea',
      rows: 4
    }
  ];

  return (
    <>
      <Grid className="safeSpace" id="baja">
        <Title>Baja de productos o servicios</Title>
        <Form
          subTitle={
            'Para solicitar la cancelación de tu crédito, completá con tus datos el siguiente formulario y te contactaremos para iniciar la solicitud:'
          }
          align={'center'}
          ctaLabel={'ENVIAR'}
          ctaWidth={'317px'}
          ctaHeight={'71px'}
          inputs={Inputs}
          subject={'Baja'}
          template={'Baja'}
        />
      </Grid>
    </>
  );
};
export default Baja;
