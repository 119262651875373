import { Typography, Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Grid)`
  margin: 5rem auto;
`;

export const Title = styled(Typography)`
  color: var(--verde) !important;
  text-align: center !important;
  margin: 1rem auto 5rem !important;
  word-break: break-word;
  max-width: 822px;
`;

export const CardContainer = styled(Grid)`
  justify-content: center;
  margin: 2rem auto 5rem;
  gap: 4rem;
`;

export const CardTitle = styled(Typography)`
  text-align: left;
  color: var(--negro);
  font-size: 1.5rem !important;
  line-height: 1.75rem !important;
  margin-bottom: 1.75rem !important;
  max-width: 288px;
`;

export const ImageContainer = styled(Box)``;

export const Line = styled('hr')`
  border: 2px solid var(--negro);
  width: 116px;
  margin: 1rem 0;
`;

export const ButtonContainer = styled(Grid)`
  justify-content: center;
  display: flex;
`;
