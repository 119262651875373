import { Grid } from '@mui/material';
import React from 'react';
import Cancelation from '../components/Cancelation/Cancelation';

/**
 * @render react
 * @name Cancelacion
 * @description Cancelacion page
 * @example
 * <Cancelacion/>
 */
const Cancelacion = () => {
  return (
    <>
      <Grid className="safeSpace" id="cancelacion">
        <Cancelation />
      </Grid>
    </>
  );
};
export default Cancelacion;
