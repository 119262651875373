import React from 'react';
/**
 * @render react
 * @name NotFound
 * @description 404 page
 * @example
 * <NotFound/>
 */
const NotFound = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <h1 style={{ fontSize: '30vw' }}>404</h1>
    </div>
  );
};
export default NotFound;
