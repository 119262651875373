import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useWow } from './hooks/useWow.js';
import './App.css';
import Home from './features/Home';
import NotFound from './features/NotFound';
import NavBar from './components/NavBar/Navbar';
import Entidades from './features/Entidades';
import Footer from './components/Footer/Footer';
import Faqs from './features/Faqs';
import Defensa from './features/Defensa';
import UsuarioFinan from './features/FinanceUser';
import Tasas from './features/Tasas';
import Cancelacion from './features/Cancelacion';
import Baja from './features/Baja';
import { abTesting } from './utils';

declare global {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/naming-convention
  interface Window {
    // eslint-disable-next-line
    dataLayer: any;
  }
}

function App() {
  const location = useLocation();
  const { initWow } = useWow();
  const [test, setTest] = useState(Boolean);
  const flag = process.env.REACT_APP_PUBLIC_FLAG;
  useEffect(() => {
    if (flag === 'true') setTest(abTesting());
  }, [flag]);

  useEffect(() => {
    initWow();
    window.scrollTo(0, 0);
  }, [initWow, location]);

  const theme = createTheme({
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      h1: {
        fontWeight: 700,
        fontSize: 48,
        lineHeight: 1.2,
        '@media (max-width: 900px)': {
          fontSize: 34
        },
        '@media (max-width: 600px)': {
          fontSize: 28
        }
      },
      h2: {
        fontWeight: 600,
        fontSize: 42,
        lineHeight: 1.2
      },
      h3: {
        fontWeight: 600,
        fontSize: 24,
        lineHeight: 1.2
      },
      h4: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 1
      },
      body1: {
        fontWeight: 400,
        fontSize: 21,
        lineHeight: 1.4,
        color: 'var(--negro)',
        '@media (max-width: 900px)': {
          fontSize: 16
        },
        '@media (max-width: 600px)': {
          fontSize: 12
        }
      },
      body2: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: 1.4
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <NavBar testRes={test} />
      <Routes>
        <Route path="/" element={<Home testRes={test} />} />
        <Route path="/entidades" element={<Entidades />} />
        <Route path="/arrepentimiento" element={<Cancelacion />} />
        <Route path="/baja" element={<Baja />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/defensa" element={<Defensa />} />
        <Route path="/tasas" element={<Tasas />} />
        <Route path="/usuario_financiero" element={<UsuarioFinan />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
