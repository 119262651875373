import { Grid } from '@mui/material';
import React from 'react';
import Faqs from '../components/Faqs/Faqs';

/**
 * @render react
 * @name Faqs
 * @description Faqs page
 * @example
 * <Faqs/>
 */
const faqs = [
  {
    title: 'about',
    questions: [
      {
        q: '¿Qué opciones de créditos ofrece Clave? ',
        a: 'Clave te ofrece créditos de diferentes originadores. Por lo tanto las opciones dependen de las políticas comerciales de cada uno de esos originadores. Ejemplos de créditos que ofrecemos: descuento de haberes y créditos para mercado abierto.'
      },
      {
        q: '¿Cuánto tarda en acreditarse un préstamo en Clave?',
        a: 'La acreditación del préstamo es inmediata a la aprobación de tu solicitud por parte de cada uno de los originadores.'
      },
      {
        q: '¿Cuánto me pueden prestar en Clave?',
        a: 'El monto máximo que te podemos prestar depende de las políticas de cada uno de los originadores y de tu solicitud.'
      },
      {
        q: '¿Cuáles son las tasas de los créditos?',
        a: 'Las tasas dependen de los originadores y respetan la normativa legal de cada país en donde Clave opera.'
      },
      {
        q: '¿Cómo desembolsan el dinero de un crédito?',
        a: 'Clave te desembolsa el dinero como prefieras: como saldo en la Clave App, como deposito en tu cuenta bancaria o una combinación de las anteriores.'
      }
    ]
  }
];
const Faq = () => {
  return (
    <>
      <Grid className="safeSpace">
        <Faqs faqs={faqs} />
      </Grid>
    </>
  );
};

export default Faq;
