import React, { useState } from 'react';

import logo from '../../assets/images/logo_ClaveCreditos.svg';
import logoMobile from '../../assets/images/logo_mobile.svg';
import claveIcon from '../../assets/icon_clave.svg';
import wpp from '../../assets/wpp.svg';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import {
  StyledList,
  StyledAppBar,
  StyledItems,
  MobileMenu,
  StyledMobileItems,
  StyledMobileList,
  LinkMobile,
  NavbarIcon,
  ClaveIcon,
  NavbarTypo,
  StyledToolbar
} from './Navbar.styles';
import { BtnLink } from '../styles/App.styles';
import burger from '../../assets/images/burger.svg';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { AbTestModel } from '../../types';

const navbarOptions = [
  {
    text: 'Inicio',
    link: '/'
  },
  {
    text: 'Entidades',
    link: '/entidades'
  }
];

const NavBar: React.FunctionComponent<AbTestModel> = ({ testRes }) => {
  const [menuActive, setMenuActive] = useState(false);
  const isMobile = useMediaQuery('(max-width: 900px)');

  const handleClick = () => {
    setMenuActive(!menuActive);
  };
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <StyledAppBar position="sticky" elevation={0}>
        <StyledToolbar>
          <Link to={`/${location.search}`}>
            <Box
              component="img"
              src={isMobile ? logoMobile : logo}
              alt="clave creditos"
              className="wow fadeInLeft"
              data-wow-delay="200ms"
            />
          </Link>
          <Box width="60%" style={{ display: 'flex' }}>
            <StyledList>
              {navbarOptions.map((option, index) => (
                <StyledItems key={option.text} className="wow fadeInUpAnimation" data-wow-delay={`${index * 100}ms`}>
                  <NavLink to={`${option.link}${location.search}`} aria-expanded>
                    {({ isActive }) => (
                      <>
                        <NavbarIcon>
                          <ClaveIcon src={claveIcon} path={currentPath} section={option.link} />
                          <NavbarTypo color={isActive ? 'var(--negro)' : 'var(--gris)'}>{option.text}</NavbarTypo>
                        </NavbarIcon>
                      </>
                    )}
                  </NavLink>
                </StyledItems>
              ))}
            </StyledList>
            <Box
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {testRes && (
                <BtnLink
                  className="wow fadeInRight"
                  data-wow-delay="200ms"
                  href={'https://public.dev.clave.cloud/u/bBWzLd'}
                  primary="true"
                  padding="0rem 1.5rem"
                  sx={{ gap: '0.5rem', lineHeight: '16px' }}
                  style={{
                    whiteSpace: 'nowrap'
                  }}
                >
                  <img src={wpp} alt="Whatsapp" />
                  Contactanos <br /> por WhatsApp
                </BtnLink>
              )}
            </Box>
          </Box>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
            sx={{
              display: { xs: 'block', md: 'none', width: '1.5em', height: '1.5em' }
            }}
          >
            <img src={burger} alt="burger" />
          </IconButton>
        </StyledToolbar>
      </StyledAppBar>

      <MobileMenu showmenu={menuActive} sx={{ display: { md: 'none' } }}>
        <StyledMobileList>
          {navbarOptions.map((option) => (
            <StyledMobileItems key={option.text}>
              <Link to={`${option.link}${location.search}`} onClick={() => setMenuActive(false)}>
                <LinkMobile>{option.text}</LinkMobile>
              </Link>
            </StyledMobileItems>
          ))}
        </StyledMobileList>
        {testRes && (
          <BtnLink
            href={'https://public.dev.clave.cloud/u/bBWzLd'}
            primary="true"
            padding="0.7rem 1.5rem"
            height="3rem"
            sx={{ gap: '0.5rem', lineHeight: '16px', marginTop: '2rem' }}
            style={{
              whiteSpace: 'nowrap'
            }}
          >
            <img src={wpp} alt="Whatsapp" />
            Contactanos <br /> por WhatsApp
          </BtnLink>
        )}
      </MobileMenu>
    </>
  );
};

export default NavBar;
