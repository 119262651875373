import { Grid } from '@mui/material';
import React from 'react';
import Defense from '../components/Defense/Defense';

/**
 * @render react
 * @name Defensa
 * @description Defensa page
 * @example
 * <Defensa/>
 */
const Defensa = () => {
  return (
    <>
      <Grid className="safeSpace" id="defensa">
        <Defense />
      </Grid>
    </>
  );
};
export default Defensa;
