import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Background, FooterLink, Title } from './Footer.styles';

function Footer() {
  const astorage = 'https://archtstoragepublic.s3.sa-east-1.amazonaws.com';
  const isMobile = useMediaQuery('(max-width: 900px)');
  const location = useLocation();
  return (
    <Background>
      <Grid container flexDirection="column" justifyContent="space-between" sx={{ maxWidth: 1800 }}>
        <Grid container spacing={0} justifyContent="center">
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: { xs: 'block', md: 'none' },
              paddingBottom: '2rem',
              marginBottom: '2rem',
              borderBottom: '1px solid white'
            }}
          >
            <img width="130px" src="/assets/img/clave_logo_blanco.svg" />
          </Grid>
          <Grid item xs={12} md={3} style={{ marginBottom: '2rem' }} className="wow fadeInAnimation" data-wow-delay="100ms">
            <Title variant="h3">Legales</Title>
            <FooterLink variant="h4">
              <a href={`${astorage}/tyc.pdf`}>Términos y condiciones </a>
            </FooterLink>
            <FooterLink variant="h4">
              <a href={`${astorage}/modeloContrato.pdf`}>Ley 24.240 | Modelo de contratos </a>
            </FooterLink>
            <FooterLink variant="h4">
              <Link to={`/usuario_financiero${location.search}`}>Información al usuario financiero</Link>
            </FooterLink>
            <FooterLink variant="h4">
              <Link to={`/defensa${location.search}`}>Defensa al consumidor</Link>
            </FooterLink>
            <FooterLink variant="h4">
              <a href={`${astorage}/pdp.pdf`}>Políticas de Privacidad</a>
            </FooterLink>
            <FooterLink variant="h4">
              <Link to={`/tasas${location.search}`}>Tasas de interés</Link>
            </FooterLink>
          </Grid>
          <Grid item xs={12} md={3} style={{ marginBottom: '2rem' }} className="wow fadeInAnimation" data-wow-delay="200ms">
            <Title variant="h3">{isMobile ? '¿Tenés dudas? Consultá las preguntas frecuentes' : 'Preguntas frecuentes'}</Title>
            <FooterLink variant="h4">
              <Link to={`/faqs${location.search}`}>Sobre Clave créditos</Link>
            </FooterLink>
            {/* <FooterLink variant="h4">
              <a href="https://clave.com/es/faqs">Sobre Clave </a>
            </FooterLink> */}
          </Grid>
          <Grid item xs={12} md={3} style={{ marginBottom: '2rem' }} className="wow fadeInAnimation" data-wow-delay="300ms">
            <Title variant="h3">Clave Créditos</Title>
            <FooterLink variant="h4">
              <Link to={`/arrepentimiento${location.search}`}>Botón de arrepentimiento</Link>
            </FooterLink>
            <FooterLink variant="h4">
              <Link to={`/baja${location.search}`}>Botón de baja</Link>
            </FooterLink>
          </Grid>
          <Grid item xs={12} sm={8} md={3} className="wow fadeInAnimation" data-wow-delay="400ms">
            <Grid item container gap={1}>
              {/* <Grid container flexDirection="row" flexWrap="nowrap" gap={1} justifyContent="center">
                <Grid item>
                  <a href="https://play.google.com/store/apps/details?id=ar.com.clave.wallet">
                    <img width="100%" src="/assets/img/google_play.svg" alt="Google Play" />
                  </a>
                </Grid>
                <Grid item>
                  <a href="https://apps.apple.com/app/id1630250753">
                    <img width="100%" src="/assets/img/app_store.svg" alt="App Store" />
                  </a>
                </Grid>
              </Grid> */}
              <Grid
                container
                flexDirection="row"
                flexWrap="nowrap"
                gap={1}
                sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}
              >
                <Grid item xs={2}>
                  <a href="http://qr.afip.gob.ar/?qr=Kccm7o5_HptXu93jJbDWBg,," target="_F960AFIPInfo">
                    <img width="100%" src="/assets/img/data_fiscal.png" />
                  </a>
                </Grid>
                <Grid item xs={8}>
                  <a href="http://www.bcra.gob.ar/BCRAyVos/Usuarios_Financieros.asp" target="_blank" rel="noreferrer">
                    <img width="100%" src="/assets/img/usuarios_financieros.png" />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="nowrap"
          sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
        >
          <Grid
            item
            xs={12}
            md={2}
            sx={{ display: { xs: 'none', md: 'block' } }}
            className="wow fadeInDownAnimation"
            data-wow-delay="100ms"
          >
            <img width="130px" src="/assets/img/clave_logo_blanco.svg" />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            className="wow fadeInDownAnimation"
            data-wow-delay="200ms"
            display="flex"
            justifyContent="flex-start"
          >
            <Typography
              variant="h4"
              sx={{ textAlign: { xs: 'center', md: 'left' }, margin: { xs: '10px 0 20px', md: '0 0 0 40px' } }}
            >
              © 2022 Clave
            </Typography>
          </Grid>
          {/* <Grid
            container
            item
            flexWrap="nowrap"
            xs={12}
            md={2}
            sx={{ justifyContent: { xs: 'space-evenly', md: 'space-between' }, margin: { xs: '20px 0 10px', md: '0px' } }}
          >
            <Grid item>
              <IconButton
                href="https://www.instagram.com/claveapp/"
                edge="start"
                color="inherit"
                aria-label="menu"
                className="wow fadeInDownAnimation"
                data-wow-delay="300ms"
              >
                <img src="/assets/img/ig.svg" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                href=" https://www.facebook.com/ClaveApp-105928372021638"
                edge="start"
                color="inherit"
                aria-label="menu"
                className="wow fadeInDownAnimation"
                data-wow-delay="400ms"
              >
                <img src="/assets/img/face.svg" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                href="https://twitter.com/ClaveApp"
                edge="start"
                color="inherit"
                aria-label="menu"
                className="wow fadeInDownAnimation"
                data-wow-delay="500ms"
              >
                <img src="/assets/img/tw.svg" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                href="https://www.linkedin.com/company/clave-wallet/"
                edge="start"
                color="inherit"
                aria-label="menu"
                className="wow fadeInDownAnimation"
                data-wow-delay="600ms"
              >
                <img src="/assets/img/linkedin.svg" />
              </IconButton>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </Background>
  );
}

export default Footer;
